<template>
    <div>
        <section class="ftco-section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-12 heading-section text-center ftco-animated">                        
                        <h2 class="mb-4">{{topic}}</h2>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12" v-html="content_html"></div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import SiteDataService from "../services/SiteDataService.vue";
import EventBus from "../Event-Bus.vue";

export default {
    data(){
        return {
            topic:"About",
            content_html:""
        }
    },
    methods:{
        GetSiteData(lang){
            SiteDataService.SiteContentGet(lang)
                .then(res => {
                    let site_content = res.data;
                    this.content_html = site_content.about_html;
                })
                .catch(err => {
                    console.log(err);
                });
        },
    },
    mounted(){
        let cklang = this.$cookies.get('showlang');
        this.GetSiteData(cklang);

        let tVue = this;
        EventBus.$on("siteLanguageChange", (_lang) => {
            tVue.GetSiteData(_lang);
        });

        EventBus.$on("AboutLabel", (_topic) => {
            tVue.topic = _topic;
        });
    }
    
}
</script>

<style scoped>

</style>